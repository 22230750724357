<template>
  <div style="display: contents">
    <v-container fluid>
      <v-row v-for="(item, index) in filtersEdit" :key="index">
        <v-col cols="2" class="pa-1">
          <v-select
            v-model="item.condition"
            :items="conditions[item.type]"
            label=""
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="8" class="pa-1">
          <v-select
            v-if="item.type == 'L'"
            class="truncate"
            v-model="item.value"
            :items="item.lov"
            hide-details="auto"
            :rules="rules"
            dense
            :label="item.text"
            multiple
            outlined
            :ref="'filter_row_' + index"
          >
            <v-list-item v-if="item.attrId == 1000001012" slot="prepend-item">
              <div class="d-flex">
                <v-btn color="orange" text @click.stop="_selectAll(item)">
                  Select/Unselect All
                </v-btn>
                <v-btn color="orange" text @click.stop="_selectAll(item, 'itjobs')">
                  IT Job Titles
                </v-btn>
                <v-btn color="orange" text @click.stop="_selectAll(item, 'itdm')">
                  IT DM's
                </v-btn>
              </div>
            </v-list-item>
            <v-list-item
              v-else-if="Array.isArray(item.lov) && item.lov.length > 0"
              slot="prepend-item"
              @click="_selectAll(item)"
            >
              <v-list-item-action>
                <v-icon :color="item.value && item.value.length > 0 ? 'primary' : ''">{{
                  _getIcon(item)
                }}</v-icon>
              </v-list-item-action>
              <v-list-item-title>Select All</v-list-item-title>
            </v-list-item>

            <v-divider
              v-if="Array.isArray(item.lov) && item.lov.length > 0"
              slot="prepend-item"
              class="mt-2"
            />
            <template v-slot:selection="{ item: chips, index: chipIdx }">
              {{ chips.text + (chipIdx == item.value.length - 1 ? "" : ",") }}
            </template>
          </v-select>

          <v-menu
            v-else-if="item.type == 'D'"
            v-model="item.dateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                hide-details="auto"
                :rules="rules"
                dense
                outlined
                v-model="item.value"
                :label="item.text"
                readonly
                v-on="on"
                :ref="'filter_row_' + index"
              ></v-text-field>
            </template>
            <v-date-picker v-model="item.value" @input="item.dateMenu = false"></v-date-picker>
          </v-menu>

          <v-text-field
            v-else
            :value="item.value"
            :type="['N', 'E'].includes(item.type) ? 'Number' : 'text'"
            @change="(v) => (item.value = v)"
            hide-details="auto"
            :rules="rules"
            dense
            outlined
            :label="item.text"
            :ref="'filter_row_' + index"
          ></v-text-field>
        </v-col>
        <v-col cols="2" class="pa-1">
          <div class="d-flex">
            <v-select
              :disabled="index == filtersEdit.length - 1"
              v-model="item.operator"
              :items="operators"
              label=""
              outlined
              dense
              hide-details
            ></v-select>
            <v-btn icon @click="removeFilter(index)" class="ml-2">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row v-show="!readOnly">
        <v-col cols="12" class="pa-1">
          <v-autocomplete
            ref="filter_select"
            @input="addNewFilter()"
            v-model="newFilterField"
            :items="fields"
            item-value="attrId"
            item-text="attrName"
            hide-details
            dense
            label="Add New Query"
            outlined
            :menu-props="{ maxWidth: '744px' }"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    rules: [(v) => !!v || "Required"],
    operators: [
      { text: "AND", value: null },
      { text: "OR", value: "UNION" }
    ],
    conditions: {
      L: [
        { value: "IN", text: "=" },
        { value: "NOT_IN", text: "!=" }
      ],
      E: [
        { value: "IN", text: "=" },
        { value: "NOT_IN", text: "!=" }
      ],
      D: [
        { value: "ON", text: "On" },
        { value: "FROM", text: "After" },
        { value: "UNTIL", text: "Util" }
      ],
      N: [
        { value: "EQUAL", text: "=" },
        { value: "NOT_EQUAL", text: "!=" },
        { value: "GREATER", text: ">" },
        { value: "LESS", text: "<" },
        { value: "GREATER_EQUAL", text: ">=" },
        { value: "LESS_EQUAL", text: "<=" }
      ],
      T: [
        { value: "EQUAL", text: "=" },
        { value: "NOT_EQUAL", text: "!=" },
        { value: "START", text: "starts with" },
        { value: "NOT_START", text: "not start with" },
        { value: "CONTAIN", text: "contains" },
        { value: "NOT_CONTAIN", text: "not contain" },
        { value: "END", text: "ends with" },
        { value: "NOT_END", text: "not end with" },
        { value: "ENCLOSED", text: "enclosed with" }
      ],
      U: [
        { value: "INCLUDE", text: "include" },
        { value: "EXCLUDE", text: "exclude" }
      ]
    },
    fields: [],
    saved_filter: [],
    filtersEdit: [],
    newFilterField: null
  }),
  methods: {
    reset() {
      this.filtersEdit = JSON.parse(JSON.stringify(this.saved_filter));
    },
    init(saved_query, fields) {
      this.filtersEdit = [];
      if (Array.isArray(fields)) {
        this.fields = fields;
      }
      if (Array.isArray(saved_query) && saved_query.length > 0) {
        saved_query.forEach((e) => {
          this.addNewFilter(e);
        });
        this.saved_filter = JSON.parse(JSON.stringify(this.filtersEdit));
      }
    },
    removeFilter(index) {
      this.filtersEdit.splice(index, 1);
      this.$emit("input", this.filtersEdit);
    },
    addNewFilter(query = undefined) {
      let selectedId = query?.ATTR_ID || this.newFilterField;
      let selectedField = this.fields[this.fields.findIndex((e) => e.attrId == selectedId)];
      if (selectedField) {
        let fieldType = selectedField.type ?? "T";
        switch (fieldType) {
          case "M":
          case "B":
            fieldType = "D";
            break;
          case "S":
          case "C":
            fieldType = "L";
            break;
        }
        this.filtersEdit.push({
          type: fieldType,
          text: selectedField.attrName,
          attrId: selectedField.attrId,
          lov: selectedField.lov,
          condition: query?.CONDITION || this.conditions[fieldType][0].value,
          operator: query?.EXTRA_OPERATOR || null,
          value: query?.VALUE
        });
        if (!query) {
          this.$nextTick(() => {
            this.newFilterField = null;
            this.$refs.filter_select.blur();
            this.$refs["filter_row_" + (this.filtersEdit.length - 1)][0].focus();
          });
        }
      }
    },
    clearAll() {
      this.filtersEdit = [];
    },
    getFilters() {
      this.saved_filter = JSON.parse(JSON.stringify(this.filtersEdit));
      return this.saved_filter.map((e, i) => {
        return {
          f: e.attrId,
          c: e.condition,
          v: e.value,
          o: this.saved_filter.length - 1 == i ? null : e.operator
        };
      });
    },
    _selectAll(obj, type = null) {
      if (type) {
        let idToSelect = [];
        if (type == "itjobs") {
          idToSelect = [
            1000001115,
            1000001116,
            1000001117,
            1000001118,
            1000001119,
            1000001120,
            1000001121,
            1000001122,
            1000001123,
            1000001124,
            1000001125,
            1000001126,
            1000001128,
            1000001129,
            1000001132
          ];
        } else if (type == "itdm") {
          idToSelect = [
            1000001115,
            1000001116,
            1000001117,
            1000001119,
            1000001123,
            1000001125,
            1000001128
          ];
        }
        obj.value = [...(obj.value ?? []), ...idToSelect];
      } else {
        if (obj.lov) {
          if (obj.value?.length === obj.lov.length) {
            obj.value = null;
          } else {
            obj.value = obj.lov.map((x) => x.value);
          }
        }
      }
    },
    _getIcon(obj) {
      let icon = "mdi-checkbox-blank-outline";
      if (Array.isArray(obj.value) && obj.value.length > 0) {
        if (obj.value.length === obj.lov.length) {
          icon = "mdi-checkbox-marked";
        } else {
          icon = "mdi-minus-box";
        }
      }
      return icon;
    }
  }
};
</script>
