<template>
  <div style="display: contents">
    <v-card-text>
      <v-container fluid>
        <v-skeleton-loader v-if="attributes.length === 0" type="list-item@20"></v-skeleton-loader>
        <v-form v-else ref="searchForm">
          <v-row v-for="(item, index) in attributes" :key="index">
            <v-col cols="2" class="pa-1">
              <v-select
                :name="'search_cond_' + item.ATTR_ID"
                :items="conditions[item.ATTR_TYPE]"
                label="Condition"
                outlined
                dense
                hide-details
                v-model="item.ACTIVE.CONDITION"
              ></v-select>
            </v-col>
            <v-col cols="10" class="pa-1">
              <v-select
                v-if="item.ATTR_TYPE == 'L'"
                :name="'search_val_' + item.ATTR_ID"
                :items="item.REF_VALUE"
                v-model="item.ACTIVE.VALUE"
                hide-details
                dense
                chips
                :label="item.ATTR_NAME"
                multiple
                outlined
                clearable
                @click:clear="clearRow(item.ACTIVE)"
              >
                <v-list-item slot="prepend-item" @click="selectAll(item)">
                  <v-list-item-action>
                    <v-icon
                      :color="item.ACTIVE.VALUE && item.ACTIVE.VALUE.length > 0 ? 'primary' : ''"
                      >{{ getIcon(item) }}</v-icon
                    >
                  </v-list-item-action>
                  <v-list-item-title>Select All</v-list-item-title>
                </v-list-item>
                <v-divider slot="prepend-item" class="mt-2" />
                <template v-slot:selection="{ item: chips, index: chipIdx }">
                  <v-chip v-if="chipIdx < 4" small>{{ chips.text }}</v-chip>
                  <span v-if="chipIdx === 4" class="grey--text caption"
                    >(+{{ item.ACTIVE.VALUE.length - 1 }} others)</span
                  >
                </template>
              </v-select>
              <v-text-field
                v-else
                :name="'search_val_' + item.ATTR_ID"
                :value="item.ACTIVE.VALUE"
                @change="(v) => (item.ACTIVE.VALUE = v)"
                :type="['N', 'E'].includes(item.ATTR_TYPE) ? 'Number' : 'text'"
                hide-details
                dense
                outlined
                clearable
                @click:clear="clearRow(item.ACTIVE)"
                :label="item.ATTR_NAME"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn plain @click="closeDialog">Cancel</v-btn>
      <v-btn plain @click="clearAll">Clear All</v-btn>
      <v-btn plain color="primary" @click="search" :loading="searchLoading">Search</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
export default {
  props: {
    ccmsId: [String, Number],
    classId: [String, Number]
  },
  data() {
    return {
      searchLoading: false,
      conditions: {
        L: [
          { value: "IN", text: "=" },
          { value: "NOT_IN", text: "!=" }
        ],
        E: [
          { value: "IN", text: "=" },
          { value: "NOT_IN", text: "!=" }
        ],
        N: [
          { value: "EQUAL", text: "=" },
          { value: "NOT_EQUAL", text: "!=" },
          { value: "GREATER", text: ">" },
          { value: "LESS", text: "<" },
          { value: "GREATER_EQUAL", text: ">=" },
          { value: "LESS_EQUAL", text: "<=" }
        ],
        T: [
          { value: "EQUAL", text: "=" },
          { value: "NOT_EQUAL", text: "!=" },
          { value: "START", text: "starts with" },
          { value: "NOT_START", text: "not start with" },
          { value: "CONTAIN", text: "contains" },
          { value: "NOT_CONTAIN", text: "not contain" },
          { value: "END", text: "ends with" },
          { value: "NOT_END", text: "not end with" }
        ]
      },
      attributes: []
    };
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        this.setup();
      }
    }
  },
  computed: {
    id() {
      return this.ccmsId + "" + this.classId;
    }
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    setup() {
      this.$axios
        .get("/database/search/load_search", {
          params: {
            ccmsId: this.ccmsId,
            classId: this.classId,
            type: "basic"
          }
        })
        .then((res) => {
          this.attributes = res.data;
        });
    },
    search() {
      let query = this.attributes
        .filter((attr) => attr.ACTIVE.VALUE && attr.ACTIVE.CONDITION)
        .map(function(attr) {
          return {
            ...attr.ACTIVE,
            ATTR_ID: attr.ATTR_ID
          };
        });

      if (query.length > 0) {
        this.searchLoading = true;
        this.$axios
          .put("/database/search/save_search", {
            query,
            ...{ ccmsId: this.ccmsId, classId: this.classId, type: "basic" }
          })
          .then(() => {
            this.$emit("search", true);
            this.closeDialog();
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            this.searchLoading = false;
          });
      } else {
        this.$store.commit("sendAlert", {
          msg: "Nothing to search for. Please make sure both condition and value are filled.",
          color: "error"
        });
      }
    },
    clearRow(obj) {
      obj.CONDITION = null;
      obj.VALUE = null;
    },
    selectAll(obj) {
      if (obj.REF_VALUE) {
        if (obj.ACTIVE.VALUE?.length === obj.REF_VALUE.length) {
          obj.ACTIVE.VALUE = null;
        } else {
          obj.ACTIVE.VALUE = obj.REF_VALUE.map((x) => x.value);
        }
      }
    },
    getIcon(obj) {
      let icon = "mdi-checkbox-blank-outline";
      if (Array.isArray(obj.ACTIVE.VALUE)) {
        if (obj.ACTIVE.VALUE.length === obj.REF_VALUE.length) {
          icon = "mdi-checkbox-marked";
        } else {
          icon = "mdi-minus-box";
        }
      }
      return icon;
    },
    clearAll() {
      this.$refs.searchForm.reset();
    }
  }
};
</script>
