<template>
  <div style="display: contents">
    <DataView
      ref="companyDV"
      v-show="!showOnlyContact"
      :headers="companyHeaders"
      objectType="company"
      @dbl-click="
        onDblClick($event);
        fetchContact($event);
      "
      @row-click="fetchContact"
      @tranverse-list="onTranverse"
      v-bind="$props"
      @update:selected="onSelected('company', $event)"
    />
    <v-divider />
    <DataView
      ref="contactDV"
      :headers="contactHeaders"
      :fillHeight="showOnlyContact"
      objectType="contact"
      @dbl-click="onDblClick"
      @tranverse-list="onTranverse"
      v-bind="$props"
      @update:selected="onSelected('contact', $event)"
    />
  </div>
</template>

<script>
import DataView from "@/components/database/data/DataView";

export default {
  components: {
    DataView
  },
  props: {
    contactHeaders: {
      type: Array,
      default: () => []
    },
    companyHeaders: {
      type: Array,
      default: () => []
    },
    showOnlyContact: {
      type: Boolean,
      default: false
    },
    selectedCompany: undefined,
    ccmsId: [String, Number],
    projectId: [String, Number],
    campaignId: [String, Number],
    classId: [String, Number]
  },
  data() {
    return {
      selected: {
        company: [],
        contact: []
      }
    };
  },
  mounted() {
    window.addEventListener("message", this.handleEvent);
  },
  destroyed() {
    window.removeEventListener("message", this.handleEvent);
  },
  methods: {
    handleEvent(e) {
      e = e.data;
      if (e.call == "tranverse") {
        if (e.objectType == "COMPANY") {
          this.$refs.companyDV.tranverse(e.direction);
        } else {
          this.$refs.contactDV.tranverse(e.direction);
        }
      } else if (e.call == "update") {
        this.$nextTick(() => {
          this.$refs.companyDV.fetchAndUpdateRow(e.companyId);
          this.$refs.contactDV.fetchAndUpdateRow(e.contactId);
        });
      } else if (e.call == "reload") {
        this.$refs.companyDV.fetchItem();
        if (this.selectedCompany) this.$refs.contactDV.fetchItem();
      }
    },
    onSelected(type, value) {
      this.selected[type] = value;
      this.$emit("update:selected", this.selected);
    },
    createNew(objectType) {
      if (objectType == "CONTACT") {
        let boundary = this.selectedCompany.boundary;
        let lrbtn = (boundary.min ? "0" : "1") + (boundary.max ? "0" : "1");
        this.openTMUI(
          this.selectedCompany.COMPANY_OBJECT_ID,
          0,
          {
            campaignId: this.campaignId,
            projectId: this.projectId,
            ccmsId: this.ccmsId,
            classId: this.classId
          },
          { type: "cm", lrbtn: lrbtn }
        );
      } else {
        this.openTMUI(0, 0, {
          campaignId: this.campaignId,
          projectId: this.projectId,
          ccmsId: this.ccmsId,
          classId: this.classId
        });
      }
    },
    onFormOpen(e, type) {
      let lrbtn = (e.boundary.min ? "0" : "1") + (e.boundary.max ? "0" : "1");
      if (type == "dblclick") {
        let contactId = e.COMPANY_OBJECT_ID == e.OBJECT_ID ? "" : e.OBJECT_ID;
        let contactType = contactId ? "ct" : "cm";
        this.tmui = this.openTMUI(
          e.COMPANY_OBJECT_ID,
          contactId,
          {
            campaignId: this.campaignId,
            projectId: this.projectId,
            ccmsId: this.ccmsId,
            classId: this.classId
          },
          { type: contactType, lrbtn: lrbtn }
        );
      } else if (type == "tranverse") {
        let contactId =
          e.nextItem.COMPANY_OBJECT_ID == e.nextItem.OBJECT_ID ? "" : e.nextItem.OBJECT_ID;
        this.tmui.postMessage(
          {
            call: "load",
            data: {
              companyId: e.nextItem.COMPANY_OBJECT_ID,
              contactId: contactId,
              ccmsId: this.ccmsId,
              type: e.objectType == "contact" ? "ct" : "cm",
              lrbtn: lrbtn,
              campaignId: this.campaignId,
              projectId: this.projectId
            }
          },
          "*"
        );
      }
    },
    onDblClick(e) {
      this.onFormOpen(e, "dblclick");
    },
    onTranverse(e) {
      this.onFormOpen(e, "tranverse");
    },
    reloadTable() {
      this.$refs.companyDV.reset();
      this.$refs.contactDV.reset();
      this.$nextTick(() => {
        if (this.showOnlyContact) {
          this.fetchContact();
        } else {
          this.fetchCompany();
        }
      });
      this.updateSelectedCompany(undefined);
    },
    fetchContact(e = null) {
      if (e) {
        this.$emit("update:selectedCompany", e);
      }

      this.$nextTick(() => {
        let companyId = null;
        if (this.selectedCompany) companyId = this.selectedCompany.COMPANY_OBJECT_ID;
        this.$refs.contactDV.fetchItem({ COMPANY_OBJECT_ID: companyId });
      });
    },
    fetchCompany() {
      this.$refs.companyDV.fetchItem();
      this.$refs.contactDV.reset();
    },
    updateSelectedCompany(val) {
      this.$emit("update:selectedCompany", val);
    }
  }
};
</script>
