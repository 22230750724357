<template>
  <v-data-table
    v-model="selectedItems"
    item-key="OBJECT_ID"
    :show-select="showSelect"
    :must-sort="true"
    :headers="headers"
    :items="items"
    :loading="loading"
    :hide-default-footer="true"
    :menu-props="{ maxHeight: '400' }"
    fixed-header
    :height="height"
    v-resize="onResize"
    :items-per-page="pageSize"
    :server-items-length="totalItem"
    @update:options="onOptionUpdate"
    :page.sync="page"
    dense
    :name="objectType + '_dv'"
    @input="onSelected"
  >
    <template v-slot:item="{ item }">
      <tr @click="handleClick(item)" :class="getRowColor(item)">
        <td v-if="showSelect">
          <v-checkbox
            style="margin: 0px; padding: 0px"
            hide-details
            :value="isChecked(item.OBJECT_ID)"
            @change="addToSelected($event, item)"
            @click.stop
          />
        </td>
        <td class="text-start v-data-table__divider" v-for="(col, i) in headers" :key="i">
          <div class="text-truncate" :style="{ 'max-width': '1000px' }">{{ item[col.value] }}</div>
        </td>
      </tr>
    </template>

    <template v-slot:footer="{ props }">
      <div class="v-data-footer">
        <div class="v-data-footer__select" v-if="props.pagination.itemsLength">
          Page Size:
          <v-select
            @change="updatePage(1)"
            hide-details
            single-line
            v-model="pageSize"
            :items="[100, 200]"
          >
          </v-select>
        </div>
        <div class="v-data-footer__select" v-if="props.pagination.itemsLength">
          Page:
          <v-select
            @change="updatePage"
            hide-details
            single-line
            v-model="page"
            :items="getPages(props.pagination.pageCount)"
          >
          </v-select>
        </div>
        <div class="v-data-footer__pagination">
          <template v-if="!props.pagination.itemsLength"> – </template>
          <template v-else>
            {{ props.pagination.pageStart + 1 }}-{{ props.pagination.pageStop }} of
            {{ props.pagination.itemsLength }}
          </template>
        </div>
        <div class="v-data-footer__icons-before">
          <v-btn
            icon
            :disabled="props.pagination.pageStart == 0"
            @click="updatePage(props.pagination.page - 1)"
            ><v-icon>mdi-chevron-left</v-icon></v-btn
          >
        </div>
        <div class="v-data-footer__icons-after">
          <v-btn
            icon
            :disabled="
              props.pagination.pageStop == props.pagination.itemsLength ||
              !props.pagination.itemsLength
            "
            @click="updatePage(props.pagination.page + 1)"
            ><v-icon>mdi-chevron-right</v-icon></v-btn
          >
        </div>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import axios from "axios";

export default {
  props: {
    headers: {
      type: Array,
      required: true
    },
    fillHeight: {
      type: Boolean,
      default: false
    },
    objectType: {
      type: String,
      default: "contact"
    },
    ccmsId: [String, Number],
    projectId: [String, Number],
    campaignId: [String, Number],
    classId: [String, Number]
  },
  data() {
    return {
      selectedItems: [],
      selected: null,
      items: [],
      totalItem: null,
      page: 1,
      realPage: 1,
      pageSize: 200,
      height: "300px",
      click: undefined,
      source: undefined,
      loading: false,
      params: {},
      sortOption: { by: [], desc: [] }
    };
  },
  computed: {
    showSelect() {
      return !this.ccmsId && this.items.length > 0;
    },
    urlToFetch() {
      let apiUrl = "/database/data/";
      if (this.campaignId) apiUrl = "/lnp/db/dbview/";
      return apiUrl + this.objectType;
    }
  },
  watch: {
    fillHeight() {
      this.onResize();
    },
    sortOption: {
      handler() {
        this.sortItem();
      },
      deep: true
    }
  },
  methods: {
    onOptionUpdate(e) {
      this.sortOption.by = e.sortBy;
      this.sortOption.desc = e.sortDesc;
    },
    onSelected(e) {
      this.$emit("update:selected", e);
    },
    isChecked(OBJECT_ID) {
      return this.selectedItems.some((e) => e.OBJECT_ID === OBJECT_ID);
    },
    addToSelected(e, item) {
      if (e) {
        this.selectedItems.push(item);
      } else {
        this.selectedItems = this.selectedItems.filter((el) => el.OBJECT_ID !== item.OBJECT_ID);
      }
    },
    tranverse(direction) {
      let obj = this.getNextIdx(direction);
      if (obj.nextIdx > -1) {
        let nextItem = this.items[obj.nextIdx];
        this.$emit("tranverse-list", {
          nextItem: nextItem,
          boundary: obj.boundary,
          objectType: this.objectType
        });
        this.selectRow(nextItem);
      }
    },
    getNextIdx(direction) {
      let idx = this.items.findIndex((e) => e.OBJECT_ID == this.selected);
      let nextIdx = idx;
      if (idx > -1) {
        if (direction) nextIdx = direction == "-" ? idx - 1 : idx + 1;
      }
      let boundary = { min: nextIdx == 0, max: this.items.length - 1 == nextIdx };

      return { nextIdx, boundary };
    },
    getPages(pageCount) {
      let lov = [];
      for (let index = 1; index <= pageCount; index++) {
        lov.push(index);
      }
      return lov;
    },
    getRowColor(item) {
      let color = null;
      if (item.OBJECT_ID == this.selected) {
        color = "blue lighten-5";
      } else if (item.DO_NOT_CALL) {
        color = "grey lighten-4";
      }

      return color;
    },
    selectRow(item) {
      this.selected = item.OBJECT_ID;
    },
    handleClick(item) {
      this.selectRow(item);
      this.clicked().then((type) => {
        let obj = this.getNextIdx();
        item.boundary = obj.boundary;
        this.$emit(type, item);
      });
    },
    clicked() {
      return new Promise((resolve) => {
        if (this.click) {
          clearTimeout(this.click);
          resolve("dbl-click");
        }
        this.click = setTimeout(() => {
          this.click = undefined;
          resolve("row-click");
        }, 500);
      });
    },
    onResize() {
      if (this.fillHeight) {
        this.height = window.innerHeight - 241;
      } else {
        this.height = (window.innerHeight - 300) / 2;
      }
    },
    updatePage(page) {
      this.realPage = page;
      this.page = this.realPage;
      this.fetchItem();
    },
    reset() {
      this.totalItem = null;
      this.items = [];
      this.selected = null;
      this.request = undefined;
    },
    fetchAndUpdateRow(objectId) {
      let row = this.items.find((e) => e.OBJECT_ID == objectId);
      if (row) {
        this.$axios
          .get(this.urlToFetch, {
            params: {
              ccmsId: this.ccmsId,
              projectId: this.projectId,
              campaignId: this.campaignId,
              objectId: objectId
            }
          })
          .then((res) => {
            if (res.data.data.length == 1) {
              Object.assign(row, res.data.data[0]);
            }
          });
      }
    },
    fetchItem(params) {
      this.selected = null;
      if (params) {
        this.params = params;
      }

      this.source && this.source.cancel("Operation canceled due to new request.");
      this.source = axios.CancelToken.source();
      this.loading = true;
      return this.$axios
        .get(this.urlToFetch, {
          params: {
            ccmsId: this.ccmsId,
            projectId: this.projectId,
            campaignId: this.campaignId,
            classId: this.classId,
            page: this.page,
            page_size: this.pageSize,
            GET_REALTIME: 1,
            ...this.params
          },
          cancelToken: this.source.token
        })
        .then((res) => {
          this.items = res.data.data;
          this.sortItem();
          this.totalItem = res.data.count;
        })
        .catch((thrown) => {
          if (axios.isCancel(thrown)) {
            // request cancelled
          } else {
            // handle error
          }
        })
        .finally(() => {
          this.loading = false;
          return true;
        });
    },
    sortItem() {
      let { by, desc } = this.sortOption;
      if (by.length === 1 && desc.length === 1) {
        this.items.sort((a, b) => {
          let sortA = a[by[0]] == null ? "" : a[by[0]];
          let sortB = b[by[0]] == null ? "" : b[by[0]];

          let order = sortA.localeCompare(sortB, undefined, { numeric: true, sensitivity: "base" });
          return desc[0] ? order * -1 : order;
        });
      }
      // prevent default behaviour of resetting to page 1 when sorted
      this.page = this.realPage;
    }
  }
};
</script>

<style>
/* hack to fix missing bottom line for 2nd v-data-table */
.v-data-table--fixed-header th {
  line-height: 0px;
}

.v-application--is-ltr .v-data-table--fixed-header .v-data-footer {
  margin-right: 0px;
}

.v-data-table th {
  background-color: #f2f2f2 !important;
}

.v-data-table__wrapper table {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
