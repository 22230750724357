<template>
  <v-dialog :value="dialog" max-width="900px" @input="closeDialog" @keydown.esc="closeDialog" eager>
    <v-card class="dialogForm">
      <v-card-title v-if="isReview">
        <v-radio-group v-model="activeType" row hide-details>
          <template #label>
            <div>Search Type:</div>
          </template>
          <v-radio label="Basic" value="basic"></v-radio>
          <v-radio label="Advance" value="advance"></v-radio>
          <v-radio label="List Retrival" value="list"></v-radio>
        </v-radio-group>
      </v-card-title>
      <keep-alive>
        <SearchBasic
          v-if="activeType == 'basic'"
          ref="searchDialog"
          v-bind="$props"
          @close="closeDialog"
          @search="search"
        />
        <SearchAdvance
          v-else-if="activeType == 'advance'"
          ref="searchDialog"
          v-bind="$props"
          @close="closeDialog"
          @search="search"
        />
        <SearchList
          v-else-if="activeType == 'list'"
          ref="searchDialog"
          v-bind="$props"
          @close="closeDialog"
          @search="search"
        />
      </keep-alive>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchBasic from "@/components/database/data/search/SearchBasic";
import SearchAdvance from "@/components/database/data/search/SearchAdvance";
import SearchList from "@/components/database/data/search/SearchList";

export default {
  components: {
    SearchBasic,
    SearchAdvance,
    SearchList
  },
  props: {
    dialog: { default: false },
    type: { type: String, default: "basic" },
    projectId: [String, Number],
    campaignId: [String, Number],
    ccmsId: [String, Number],
    classId: [String, Number]
  },
  computed: {
    // if true, accessed from database/data
    isReview() {
      return !!this.classId;
    }
  },
  data() {
    return {
      activeType: null
    };
  },
  created() {
    this.activeType = this.type;
    if (this.isReview) {
      this.setup();
    }
  },
  methods: {
    closeDialog() {
      this.$emit("update:dialog", false);
    },
    search() {
      this.$emit("search", true);
    },
    reload() {
      this.$refs.searchDialog.setup();
    },
    setup() {
      this.$axios
        .get("/database/search/get_active_search_type")
        .then((res) => {
          if (res.data) {
            this.activeType = res.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
