<template>
  <v-card class="dialogForm">
    <v-card-title>List Retrival</v-card-title>
    <v-card-text> List Name: [ *** ] </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn @click="closeDialog">Cancel</v-btn>
      <v-btn @click="clearAll">Clear All</v-btn>
      <v-btn color="primary" @click="search" :loading="searchLoading">Search</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    classId: [String, Number]
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    closeDialog() {
      this.$emit("close", true);
    }
  }
};
</script>
