<template>
  <div style="display: contents">
    <v-card-text>
      <v-container fluid>
        <v-form>
          <v-row>
            <v-switch
              v-model="isDupSearch"
              label="Find duplicates"
              hide-details
              class="mb-2"
            ></v-switch>
            <template v-if="isDupSearch">
              <v-col cols="12" class="pa-1">
                <v-autocomplete
                  v-model="dupFilter.value"
                  :items="dupFields"
                  label="based on ..."
                  dense
                  outlined
                  multiple
                  :hide-details="!isLNP"
                  persistent-hint
                  hint="Click on the chip to toggle between Exact Match (=) or Fuzzy Logic (~) search."
                  @change="_addDup"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      small
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click.stop="_toggleSearchType(data.index)"
                      @click:close="_remove(data.item.value)"
                    >
                      <b class="mr-1" v-if="isLNP">{{
                        dupFilter.type[data.index] == "n" ? "=" : "~"
                      }}</b>
                      {{ data.item.text }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </template>
            <v-col cols="12" class="mb-3">
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <Query ref="query" />
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn plain @click="closeDialog">Close</v-btn>
      <v-btn plain @click="clearAll">Clear All</v-btn>
      <v-btn plain color="primary" @click="search" :loading="loading">Search</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import Query from "@/components/database/Query";

export default {
  components: {
    Query
  },
  props: {
    projectId: [String, Number],
    campaignId: [String, Number],
    classId: [String, Number],
    dialog: [Boolean]
  },
  data: () => ({
    dupFilter: {
      value: [],
      type: []
    },
    isDupSearch: false,
    dupFields: [],
    loading: false
  }),
  watch: {
    dialog(v) {
      if (v) {
        // reset selection to saved query
        this.$refs.query.reset();
      }
    },
    projectId: {
      immediate: true,
      handler() {
        this.setup();
      }
    }
  },
  computed: {
    apiUrl() {
      return this.isLNP ? "/lnp/db/dbview/" : "/database/search/";
    },
    isLNP() {
      return !this.classId;
    }
  },
  methods: {
    setup() {
      this.loading = true;
      this.$axios
        .get(this.apiUrl + "load_search", {
          params: {
            campaignId: this.campaignId,
            projectId: this.projectId,
            classId: this.classId,
            type: "advance"
          }
        })
        .then((res) => {
          this.dupFields = res.data.dupFields;
          if (res.data.saved_query_dup.value?.length > 0) {
            this.dupFilter = res.data.saved_query_dup;
            this.isDupSearch = true;
          }
          this.$refs.query.init(res.data.saved_query, res.data.fields);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeDialog() {
      this.$emit("close", false);
    },
    search() {
      let filters = this.$refs.query.getFilters();
      this.$axios
        .put(this.apiUrl + "save_search", {
          query: filters,
          campaignId: this.campaignId,
          projectId: this.projectId,
          classId: this.classId,
          dup_query: this.isDupSearch === true ? this.dupFilter : false,
          type: "advance"
        })
        .then(() => {
          this.$emit("search", true);
          this.closeDialog();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearAll() {
      this.$refs.query.clearAll();
    },
    _toggleSearchType(i) {
      let tmp = this.dupFilter.type;
      tmp[i] = tmp[i] == "n" ? "f" : "n";
      //need to reset value to update ui
      this.dupFilter.type = [];
      this.dupFilter.type = tmp;
    },
    _addDup(e) {
      this.dupFilter.type = [];
      e.forEach((attrId, i) => {
        this.dupFilter.type[i] = "n";
      });
    },
    _remove(value) {
      const index = this.dupFilter.value.indexOf(value);
      if (index >= 0) {
        this.dupFilter.value.splice(index, 1);
        if (this.dupFilter.type) {
          this.dupFilter.type.splice(index, 1);
        }
      }
    }
  }
};
</script>
